import { PaginationRequestDef, PaginationResponseDef } from "@app/types/pagination.types";
import { FileDef } from "./super-admin.user.types";

export enum ECVParserStatus {
  QUEUED = "queued",
  PROCESSING = "processing",
  COMPLETED = "completed",
  FAILED = "failed",
}

export type GetCVParserProcessSAParamsDef = PaginationRequestDef & {
  workerProfileId: string;
  orderBy?: {
    fieldName: string;
    order: "ASC" | "DESC";
  }[];
};

export interface CVParserProcessSADef {
  id: string;
  status: ECVParserStatus;
  lang: string;
  medias: FileDef[];
  processStartedAt: string;
  workerProfileId: string;
  version: number;
  createdAt: string;
  updatedAt: string;
}

export type CVParserProcessSAWithPaginationDef = PaginationResponseDef<CVParserProcessSADef>;
