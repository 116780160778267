import { LockOutlined, MailOutlined, ShopOutlined, UserOutlined } from "@ant-design/icons";
import { Button } from "@app/components/ui/Button/Button";
import { PhoneNumberInput } from "@app/components/ui/PhoneNumberInput/PhoneNumberInput";
import { ENV } from "@app/constants/env";
import { PASSWORD_REGEX } from "@app/constants/validation.constants";
import { signup } from "@app/features/auth/api/auth.api";
import { LocalClaimForm, LocalSignupForm } from "@app/features/auth/types/auth.types";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { getAllUTMs, triggerAnalyticsEvent, triggerIdentify } from "@app/helpers/analytics.helper";
import { isInvalidCompanyEmail } from "@app/helpers/form.helper";
import { convertLangForAnalytics, convertLangForBackend } from "@app/helpers/language.helper";
import { RootState, store } from "@app/store/store";
import { Col, Form, Input, Row, message } from "antd";
import { Rule } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";
import clsx from "clsx";
import { useFeatureFlagVariantKey } from "posthog-js/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthHeader } from "../AuthHeader/AuthHeader";
import { AuthHeroLogos, AuthHeroTitles } from "../AuthHero/AuthHero";
import { AuthRedirectButton } from "../AuthRedirectButton/AuthRedirectButton";

import styles from "./Signup.module.scss";

export const Signup = () => {
  const { t, i18n } = useTranslation();
  const [form] = useForm<LocalSignupForm | LocalClaimForm>();
  const [allFieldsTouched, setAllFieldsTouched] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const loading = useSelector((state: RootState) => state.loading.models.auth);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { dispatch } = store;
  const showPhone = useFeatureFlagVariantKey("company-sign-up-phone") === "show";

  // Only runs once due to no dependencies
  useEffect(() => {
    message.destroy();

    const getCompanyNameById = async (companyId: string) => {
      const { data } = await axios.get(`/crafthunts/company_resources/${companyId}`, {
        baseURL: ENV.API_URL,
        params: {
          simpleSearch: true,
        },
        headers: {
          registerapp: "crafthunt",
          "x-access-token": ENV.API_KEY,
        },
      });
      if (data?.name) {
        form.setFieldsValue({ name: data.name });
      }
    };

    if (searchParams.get("firstName")) {
      form.setFieldsValue({ firstName: searchParams.get("firstName") as string });
    }
    if (searchParams.get("lastName")) {
      form.setFieldsValue({ lastName: searchParams.get("lastName") as string });
    }
    if (searchParams.get("email")) {
      form.setFieldsValue({ email: searchParams.get("email") as string });
    }
    if (searchParams.get("phone")) {
      form.setFieldsValue({ phone: searchParams.get("phone") as string });
    }
    if (searchParams.get("companyName")) {
      form.setFieldsValue({ name: searchParams.get("companyName") as string });
    }
    if (searchParams.get("companyId")) {
      form.setFieldsValue({ companyId: searchParams.get("companyId") as string });
      const companyId = searchParams.get("companyId");
      getCompanyNameById(companyId as string);
    }
    if (searchParams.get("nonce")) {
      form.setFieldsValue({ nonce: searchParams.get("nonce") as string });
    }
  }, []);

  const onFormChange = () => {
    // Use setTimeout here to avoid outOfDate error when checking form for errors.
    // See https://github.com/ant-design/ant-design/issues/26747#issuecomment-693165910
    setTimeout(() => {
      setAllFieldsTouched(form.isFieldsTouched(true));
      setHasErrors(form.getFieldsError().some(({ errors }) => errors.length));
    });
  };

  const handleFinish = async (values: LocalSignupForm | LocalClaimForm) => {
    if (searchParams.get("companyId") && searchParams.get("nonce")) {
      try {
        await dispatch.auth.claim({
          ...values,
          companyId: searchParams.get("companyId") as string,
          nonce: searchParams.get("nonce") as string,
          lang: convertLangForBackend(i18n.language),
        });
        triggerAnalyticsEvent("company_claim_completed", {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          name: values.name,
          language: convertLangForAnalytics(i18n.language),
        });
        await dispatch.auth.login({ email: values.email, password: values.password });
        message.success({ type: "success", content: t("Logged in!") }, 1000);
        navigate(RoutePaths.DASHBOARD);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 400) {
            message.error({ type: "error", content: t("This email has already been registered.") });
          }
        } else {
          message.error({ type: "error", content: t("Sign up failed.") });
        }
      }
    } else {
      try {
        const utmParams = getAllUTMs();
        const workerProfile = await signup({
          ...values,
          lang: convertLangForBackend(i18n.language),
          utmParams,
        });
        triggerIdentify(workerProfile.id, {
          companyName: values.name,
          email: workerProfile.email,
          phone: workerProfile.phone,
        });
        triggerAnalyticsEvent("company_signup_completed", {
          firstName: workerProfile.firstName,
          lastName: workerProfile.lastName,
          email: workerProfile.email,
          phone: workerProfile.phone,
          name: values.name,
          language: convertLangForAnalytics(i18n.language),
        });
        await dispatch.auth.login({ email: values.email, password: values.password });
        message.success({ type: "success", content: t("Logged in!") }, 1000);
        navigate(RoutePaths.DASHBOARD);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 409) {
            message.error({ type: "error", content: t("This email has already been registered.") });
          }
        } else {
          message.error({ type: "error", content: t("Sign up failed.") });
        }
      }
    }
  };

  const companyNameRules: Rule[] = [
    { required: true, whitespace: true, message: t("Please enter your company name") },
  ];
  const firstNameRules: Rule[] = [
    { required: true, whitespace: true, message: t("Please enter your first name!") },
  ];
  const lastNameRules: Rule[] = [
    { required: true, whitespace: true, message: t("Please enter your last name!") },
  ];
  const emailRules: Rule[] = [
    {
      type: "email",
      required: true,
      whitespace: true,
      message: t("Invalid email"),
    },
    {
      validator: (_, value) => {
        if (isInvalidCompanyEmail(value)) {
          return Promise.reject(t("Only professional emails are allowed!"));
        }
        return Promise.resolve();
      },
    },
  ];
  const phoneRules: Rule[] = [
    {
      type: "string",
      required: true,
      whitespace: true,
      message: t("Not valid phone number"),
    },
    {
      validator: (_, value: string) => {
        if (!value || value.length <= 4) {
          return Promise.resolve();
        }
        if (!isPossiblePhoneNumber(value)) {
          return Promise.reject(t("Not valid phone number"));
        }
        return Promise.resolve();
      },
    },
  ];
  const passwordRules: Rule[] = [
    {
      required: true,
      pattern: PASSWORD_REGEX,
      message: t(
        "At least 8 characters, including one lowercase letter, one capital letter, one number."
      ),
    },
  ];
  const passwordConfirmRules: Rule[] = [
    {
      required: true,
      message: t("Please confirm your password!"),
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue("password") === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(t("The two passwords that you entered do not match!")));
      },
    }),
  ];

  return (
    <>
      <div className={styles.hero}>
        <AuthHeroTitles />
        <AuthHeroLogos />
      </div>
      <AuthHeader title={t("sign-up-form-title")} />
      <Form form={form} onFinish={handleFinish} layout="vertical" onChange={onFormChange}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item name="name" label={t("Company name")} hasFeedback rules={companyNameRules}>
              <Input
                prefix={<ShopOutlined />}
                type="text"
                placeholder={t("sign-up-company-name-placeholder")}
                size="large"
                disabled={!!(searchParams.get("companyId") && searchParams.get("nonce"))}
                name="company" // HUBSPOT ID
              />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item name="firstName" label={t("First name")} hasFeedback rules={firstNameRules}>
              <Input
                type="text"
                prefix={<UserOutlined />}
                placeholder={t("sign-up-name-placeholder")}
                size="large"
                name="firstname" // HUBSPOT ID
              />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item name="lastName" label={t("Last name")} hasFeedback rules={lastNameRules}>
              <Input
                type="text"
                prefix={<UserOutlined />}
                placeholder={t("sign-up-surname-placeholder")}
                size="large"
                name="lastname" // HUBSPOT ID
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="email" label={t("sign-up-email")} hasFeedback rules={emailRules}>
              <Input
                type="text"
                prefix={<MailOutlined />}
                placeholder={t("sign-up-email-placeholder")}
                size="large"
                name="email" // HUBSPOT ID
              />
            </Form.Item>
          </Col>
          {showPhone && (
            <Col span={24}>
              <Form.Item name="phone" label={t("sign-up-phone")} rules={phoneRules}>
                <PhoneNumberInput
                  placeholder={t("Enter phone number")}
                  name="phone" // HUBSPOT ID
                />
              </Form.Item>
            </Col>
          )}
          <Col span={24} sm={12}>
            <Form.Item
              name="password"
              label={t("sign-up-password")}
              hasFeedback
              rules={passwordRules}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="***"
                size="large"
                name="password" // HUBSPOT ID
              />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item
              name="passwordConfirm"
              label={t("Confirm password")}
              hasFeedback
              rules={passwordConfirmRules}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="***"
                size="large"
                name="password" // HUBSPOT ID
              />
            </Form.Item>
          </Col>
        </Row>
        <div className={styles.buttons}>
          <Form.Item noStyle>
            <Button
              className={clsx(styles.signup, (!allFieldsTouched || hasErrors) && styles.disabled)}
              disabled={!allFieldsTouched || hasErrors}
              id="sign-up-button"
              loading={loading}
              type="primary"
              htmlType="submit"
              block
              size="large"
            >
              {t("sign-up-button-title")}
              <span className={styles.signupSubtitle}>{t("sign-up-button-subtitle")}</span>
            </Button>
          </Form.Item>
          <AuthRedirectButton
            text={t("Already have an account?")}
            actionText={t("sign-up-action-text")}
            path={RoutePaths.LOGIN}
          />
        </div>
      </Form>
    </>
  );
};
