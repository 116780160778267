import { updateUserSA, useUserSA } from "@app/features/super-admin/api/super-admin.user.api";
import { mapUserToForm } from "@app/features/super-admin/helpers/super-admin.helper";
import { useMutation } from "@tanstack/react-query";
import { Alert, Form, Input, message, Spin } from "antd";
import { useEffect, useMemo } from "react";

export const UserForm = ({ userId }: { userId: string }) => {
  const [form] = Form.useForm<typeof initialValues>();

  const userQuery = useUserSA({
    userId,
  });

  useEffect(() => {
    form.resetFields();
  }, [userQuery.data]);

  const initialValues = useMemo(() => {
    return userQuery.data ? mapUserToForm(userQuery.data) : null;
  }, [userQuery.data]);

  const editUserProgress = useMutation({
    mutationFn: updateUserSA,
    onSettled: () => {
      userQuery.refetch();
    },
    onError: () => {
      message.error("Sorry, something went wrong.");
    },
  });

  return (
    <>
      {userQuery.isLoading && <Spin />}
      {userQuery.error && <Alert type="error" message="Failed to load user data" />}
      {initialValues && (
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          style={{
            width: "100%",
          }}
          onFinish={(values) => {
            if (values) {
              editUserProgress.mutate({ id: userId, internalComment: values.internalComment });
            }
          }}
        >
          <Form.Item
            validateStatus={editUserProgress.isPending ? "validating" : undefined}
            hasFeedback={editUserProgress.isPending}
            label="Internal comment"
            name="internalComment"
          >
            <Input.TextArea
              placeholder="Enter..."
              size="large"
              autoSize={{
                minRows: 4,
                maxRows: 4,
              }}
              onBlur={() => {
                form.submit();
              }}
            />
          </Form.Item>
        </Form>
      )}
    </>
  );
};
