import { ArrowRightOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { DateFormats } from "@app/constants/date.constants";
import { useCandidatesSA } from "@app/features/super-admin/api/super-admin.candidate.api";
import { SUPER_ADMIN_PAGE_SIZE } from "@app/features/super-admin/constants/super-admin.constants";
import { CandidateSADef } from "@app/features/super-admin/types/super-admin.candidate.types";
import { UserFormDef } from "@app/features/super-admin/types/super-admin.user.types";
import { ECandidateApprovalAction, ECandidateStatus } from "@app/types/candidate.types";
import { Col, Modal, Row, Space, Table, TableColumnsType, Tag, Typography } from "antd";
import { TablePaginationConfig } from "antd/es/table/interface";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactMoment from "react-moment";
import { useCompanyModal } from "../Companies/components/CompanyModal";
import { CandidateDrawer } from "../components/CandidateDrawer/CandidateDrawer";
import { CompanyFilter } from "../Filters/CompanyFilter/CompanyFilter";
import { UserFilter } from "../Filters/UserFilter/UserFilter";
import { useJobModal } from "../Jobs/JobModal/JobModal";
import { USER_MODAL_TAB_KEYS } from "../Users/components/UserModal/UserModal";
import { useUserModalGlobal } from "../Users/components/UserModal/UserModalGlobal";
import { ApprovalModal } from "./ApprovalModal";

type Props = {
  selectedUser?: UserFormDef;
  isActive: boolean;
  onCountChange: (count: number) => void;
};

export type ApprovalState = {
  candidate: CandidateSADef | null;
  action: ECandidateApprovalAction;
};

export const Approvals = ({ selectedUser, isActive, onCountChange }: Props) => {
  const { t } = useTranslation();
  const { openUserModal } = useUserModalGlobal();
  const { setCompanyId } = useCompanyModal();
  const { setJobId } = useJobModal();
  const [page, setPage] = useState(0);
  const [showCandidateDrawer, setShowCandidateDrawer] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState<CandidateSADef | undefined>();
  const [approvalState, setApprovalState] = useState<ApprovalState>({
    candidate: null as null | CandidateSADef,
    action: ECandidateApprovalAction.APPROVE,
  });

  const [companyIdForFilter, setCompanyIdForFilter] = useState<string>();
  const [userIdForFilter, setUserIdForFilter] = useState<string | undefined>(selectedUser?.id);
  const showFilters = !selectedUser;

  const candidatesQuery = useCandidatesSA(
    {
      limit: SUPER_ADMIN_PAGE_SIZE,
      offset: page,
      companyId: companyIdForFilter,
      workerProfileId: userIdForFilter,
      status: ECandidateStatus.NEEDS_APPROVAL,
    },
    {
      refetchInterval: 60000,
    }
  );

  useEffect(() => {
    onCountChange(candidatesQuery.data?.count || 0);
  }, [candidatesQuery.data?.count]);

  const handlePageChange = async (pagination: TablePaginationConfig) => {
    if (typeof pagination.current === "number" && typeof pagination.pageSize === "number") {
      setPage(pagination.current - 1);
    }
  };

  const closeApprovalModal = () => {
    setApprovalState((state) => ({ ...state, candidate: null }));
  };

  const generatedData = (data: CandidateSADef[]) => {
    return data?.map((item) => ({
      key: item.id,
      isScrapedJob: (
        <Tag color={item.crafthuntCompany.isClaimed ? "green" : "red"}>
          {item.crafthuntCompany.isClaimed ? t("No") : t("Yes")}
        </Tag>
      ),
      companyName: (
        <a onClick={() => setCompanyId(item.crafthuntCompany.id)}>{item.crafthuntCompany.name}</a>
      ),
      jobAds: item.jobAds,
      jobName:
        item.jobAds.length === 1 ? (
          <a onClick={() => setJobId(item.jobAds[0].id)}>{item.jobAds[0].title}</a>
        ) : item.jobAds.length > 1 ? (
          ` ${item.jobAds.length} ${t("Job ads")}`
        ) : (
          ""
        ),
      candidateName: !item.workerProfile.deletedAt ? (
        <a
          onClick={() => {
            openUserModal({ userId: item.workerProfile.id });
          }}
        >
          {[item.workerProfile.firstName, item.workerProfile.lastName].join(" ")}
        </a>
      ) : (
        [item.workerProfile.firstName, item.workerProfile.lastName].join(" ")
      ),
      createdAt: (
        <ReactMoment withTitle titleFormat={DateFormats.FULL_DATE_TIME} fromNow>
          {item.createdAt}
        </ReactMoment>
      ),
      operation: (
        <Space size="middle">
          <a
            onClick={() => {
              setSelectedCandidate(item);
              setShowCandidateDrawer(true);
            }}
          >
            {t("Details")}
          </a>
          <a
            onClick={() => {
              setApprovalState({
                candidate: item,
                action: ECandidateApprovalAction.APPROVE,
              });
            }}
          >
            {t("approve")}
          </a>
          <a
            onClick={() => {
              setApprovalState({
                candidate: item,
                action: ECandidateApprovalAction.REJECT,
              });
            }}
          >
            {t("reject")}
          </a>
        </Space>
      ),
    }));
  };

  if (!isActive) {
    return null;
  }

  const columns: TableColumnsType<ReturnType<typeof generatedData>[number]> = [
    {
      title: "Scraped",
      dataIndex: "isScrapedJob",
      key: "isScrapedJob",
      ellipsis: true,
      width: 100,
    },
    {
      title: t("Company name"),
      dataIndex: "companyName",
      key: "companyName",
      ellipsis: true,
      width: 250,
    },
    { title: t("Job ads"), dataIndex: "jobName", key: "jobName", ellipsis: true },
    {
      title: t("candidate-name-label"),
      dataIndex: "candidateName",
      key: "candidateName",
      ellipsis: true,
      width: 300,
    },
    { title: t("Created at"), dataIndex: "createdAt", key: "createdAt", width: 200 },
    {
      title: t("Actions"),
      dataIndex: "operation",
      key: "operation",
      fixed: "right",
      width: 250,
    },
  ];

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      {showFilters && (
        <Row gutter={[16, 16]} align="middle">
          <Col span={12}>
            <CompanyFilter onChange={setCompanyIdForFilter} />
          </Col>
          <Col span={12}>
            <UserFilter value={userIdForFilter} onChange={setUserIdForFilter} />
          </Col>
        </Row>
      )}
      <Table
        style={{ width: "100%" }}
        columns={columns}
        dataSource={generatedData(candidatesQuery.data?.data ?? [])}
        loading={candidatesQuery.isLoading}
        onChange={handlePageChange}
        scroll={{ x: 1250 }}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <Typography.Text>{t("Job ads")}</Typography.Text>
              <ul>
                {record.jobAds.map((job) => (
                  <li key={job.id}>
                    <a onClick={() => setJobId(job.id)}>{job.title}</a>
                  </li>
                ))}
              </ul>
            </>
          ),
          rowExpandable: (record) => record.jobAds.length > 1,
        }}
        pagination={{
          current: page + 1,
          total: candidatesQuery.data?.count,
          showTotal: (total) => `${t("Total")} ${total} ${t("applications-label")}`,
          pageSize: SUPER_ADMIN_PAGE_SIZE,
          showSizeChanger: false,
          position: ["bottomLeft"],
        }}
      />
      {selectedCandidate && (
        <CandidateDrawer
          key={selectedCandidate.id}
          open={showCandidateDrawer}
          onClose={() => setShowCandidateDrawer(false)}
          candidate={selectedCandidate}
        />
      )}
      <ApprovalModal
        key={"approval" + approvalState.candidate?.id}
        {...approvalState}
        onSuccess={() => {
          Modal.confirm({
            title: "Do you want to recommend this candidate to another company?",
            okType: "primary",
            okButtonProps: {
              icon: <ArrowRightOutlined />,
            },
            okText: "Recommend",
            cancelText: "No thanks",
            cancelButtonProps: {
              icon: <CloseCircleOutlined />,
            },
            onOk() {
              if (approvalState.candidate) {
                openUserModal({
                  userId: approvalState.candidate.workerProfile.id,
                  tab: USER_MODAL_TAB_KEYS.RECOMMENDATIONS,
                });
              }
            },
          });

          closeApprovalModal();
        }}
        onCancel={closeApprovalModal}
        onSettled={() => candidatesQuery.refetch()}
      />
    </Space>
  );
};
