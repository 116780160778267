import {
  SelectAutocomplete,
  SelectAutocompleteProps,
} from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { RootState } from "@app/store/store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const RolesFilter = (props: SelectAutocompleteProps) => {
  const { t } = useTranslation();
  const jobTitles = useSelector((state: RootState) => state.tags.jobTitles);
  const jobTitleChoices = jobTitles.map((i) => ({
    value: i.id,
    label: t(i.name),
  }));

  return (
    <SelectAutocomplete
      mode="multiple"
      placeholder={t("roles-select")}
      options={jobTitleChoices}
      allowClear
      {...props}
    />
  );
};
