import { useCompaniesSA } from "@app/features/super-admin/api/super-admin.company.api";
import { SUPER_ADMIN_PAGE_SIZE } from "@app/features/super-admin/constants/super-admin.constants";
import { CompanySADef } from "@app/features/super-admin/types/super-admin.company.types";
import { EngagementSAColumnsDataDef } from "@app/features/super-admin/types/super-admin.engagement.types";
import { Input, Space, Table, TableColumnsType, TablePaginationConfig, Tabs } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyModal } from "../../../Companies/components/CompanyModal";
import { EngagementStatus } from "./components/EngagementStatus";

type Props = {
  selectedUserId?: string;
};

enum TAB_KEYS {
  COMPANY = "company",
  JOB = "job",
}

export const RecommendationsTab = ({ selectedUserId }: Props) => {
  const [activeTab, setActiveTab] = useState(TAB_KEYS.COMPANY);

  return <CompaniesTable userId={selectedUserId} />;

  return (
    <Tabs
      size="small"
      activeKey={activeTab}
      onChange={(tabKey) => setActiveTab(tabKey as TAB_KEYS)}
      style={{ width: "100%", flex: 1 }}
      items={[
        {
          key: TAB_KEYS.COMPANY,
          label: "Recommend user to company",
          children: <CompaniesTable userId={selectedUserId} />,
        },
        {
          key: TAB_KEYS.JOB,
          label: "Recommend job to user",
          children: <div>Recommend jobs tab is in development</div>,
        },
      ]}
    />
  );
};

const CompaniesTable = ({ userId }: { userId?: string }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState("");
  const { setCompanyId } = useCompanyModal();

  const companiesQuery = useCompaniesSA({
    keyword,
    isPaying: true,
    isClaimed: true,
    isTestCompany: false,
    limit: SUPER_ADMIN_PAGE_SIZE,
    offset: page,
  });

  const columns: TableColumnsType<EngagementSAColumnsDataDef> = [
    { title: t("Company name"), dataIndex: "companyName", key: "companyName", width: 150 },
    { title: t("Status"), dataIndex: "status", key: "status", fixed: "right", width: 100 },
  ];

  const generatedData = (data: CompanySADef[]): EngagementSAColumnsDataDef[] => {
    return data.map((item) => ({
      key: item.id,
      companyName: <a onClick={() => setCompanyId(item.id)}>{item.name}</a>,
      status: <EngagementStatus companyId={item.id} userId={userId} />,
    }));
  };

  const handlePageChange = async (pagination: TablePaginationConfig) => {
    if (typeof pagination.current === "number" && typeof pagination.pageSize === "number") {
      setPage(pagination.current - 1);
    }
  };

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Input.Search
        placeholder={t("Search for company id, company name or contact email")}
        enterButton={t("Search")}
        size="large"
        onSearch={(value) => {
          setPage(0);
          setKeyword(value);
        }}
        allowClear
      />
      <Table
        style={{ width: "100%" }}
        loading={companiesQuery.isLoading}
        columns={columns}
        scroll={{ x: 1270 }}
        dataSource={generatedData(companiesQuery.data?.data ?? [])}
        pagination={{
          current: page + 1,
          total: companiesQuery.data?.count,
          showTotal: (total) => `${t("Total")} ${total} ${t("Companies")}`,
          pageSize: SUPER_ADMIN_PAGE_SIZE,
          showSizeChanger: false,
        }}
        onChange={handlePageChange}
      />
    </Space>
  );
};
