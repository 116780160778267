import { Typography } from "antd";
import styles from "./AuthHero.module.scss";
import { useTranslation } from "react-i18next";
import { StarFilled } from "@ant-design/icons";
import { Logo } from "@app/components/ui/Logo/Logo";
import logoBauer from "./logos/bauer.webp";
import logoDrees from "./logos/drees&sommer.webp";
import logoEiffage from "./logos/eiffage.webp";
import logoGeiger from "./logos/geiger.webp";
import logoOctopus from "./logos/octopus_energy.webp";
import logoSpie from "./logos/spie.webp";
import logoStrabag from "./logos/strabag.webp";
import logoZausinger from "./logos/zausinger.webp";
import clsx from "clsx";

const logos = [
  logoBauer,
  logoDrees,
  logoGeiger,
  logoOctopus,
  logoEiffage,
  logoSpie,
  logoZausinger,
  logoStrabag,
];

const TESTIMONIALS = [
  {
    image: "https://a.storyblok.com/f/123131/583x583/078168ccfe/philipprochollbubble.jpg?cv=NaN",
    title: "testimonial-quote-rocholl-updated",
    author: "Philipp Rocholl",
    authorRole: "testimonial-role-rocholl",
    authorCompany: "Rocholl Garten-, Landschafts- und Tiefbau GmbH",
    authorLink:
      "https://crafthunt.app/companies/rocholl-garten-landschafts-und-tiefbau-gmb-h-wEUjuoz6",
    companyLogo: "https://a.storyblok.com/f/123131/x/ecd05761cf/rocholl_logo_header.svg",
    companyLogoHeight: 50,
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/prod-crafthunt/o/admin-panel%2FFalk.jpeg?alt=media&token=50a30344-6179-4b66-aa95-6aa8a703e114",
    title: "testimonial-quote-catterfeld",
    author: "Falk Hartmann",
    authorRole: "testimonial-role-catterfeld",
    authorCompany: "Catterfeld Welker GmbH",
    authorLink: "https://crafthunt.app/companies/catterfeld-welker-gmbh-arz941sl",
    companyLogo:
      "https://firebasestorage.googleapis.com/v0/b/prod-crafthunt/o/catterfeld_welker_logo_white.webp?alt=media&token=8be1810a-d82d-4eaa-a182-d45839193d23",
    companyLogoHeight: 85,
  },
];

export const AuthHero = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.mainLogo}>
          <Logo />
        </div>

        <AuthHeroTitles />

        <div className={styles.items}>
          <Typography.Text className={styles.item}>✓ {t("sign-up-list-item-1")}</Typography.Text>
          <Typography.Text className={styles.item}>✓ {t("sign-up-list-item-2")}</Typography.Text>
          <Typography.Text className={styles.item}>✓ {t("sign-up-list-item-3")}</Typography.Text>
        </div>

        {TESTIMONIALS.slice(0, 1).map((item) => (
          <section key={item.title} className={styles.review}>
            <figure className={styles.figure}>
              <div className={styles.stars}>
                <StarFilled width={30} aria-hidden="true" className={styles.star} />
                <StarFilled aria-hidden="true" className={styles.star} />
                <StarFilled aria-hidden="true" className={styles.star} />
                <StarFilled aria-hidden="true" className={styles.star} />
                <StarFilled aria-hidden="true" className={styles.star} />
              </div>
              <blockquote className={styles.quote}>
                <p>“{t(item.title)}”</p>
              </blockquote>
              <figcaption className={styles.figcaption}>
                <img alt="" src={item.image} className={styles.avatar} />
                <div>
                  <div className={styles.author}>{item.author}</div>
                  <a
                    href={item.authorLink}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.role}
                  >
                    {t(item.authorRole, { 0: item.authorCompany })}
                  </a>
                </div>
              </figcaption>
            </figure>
          </section>
        ))}

        <AuthHeroLogos />
      </div>
    </div>
  );
};

export const AuthHeroTitles = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.titles}>
      <Typography.Title level={1} className={styles.title}>
        {t("sign-up-title")} <br className={styles.br} />
        <span className={styles.subtitle}>{t("sign-up-subtitle")}</span>
      </Typography.Title>
    </div>
  );
};

export const AuthHeroLogos = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.logos}>
      <div className={clsx(styles.logosTitle, styles.desktop)}>{t("sign-up-logo-cloud-title")}</div>
      <div className={clsx(styles.logosTitle, styles.mobile)}>
        {t("sign-up-logo-cloud-title-mobile")}
      </div>
      <div className={styles.logosList}>
        {logos.map((logo) => (
          <img src={logo} alt="" key={logo} className={styles.logo} />
        ))}
      </div>
    </div>
  );
};
