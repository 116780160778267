import {
  SelectAutocomplete,
  SelectAutocompleteProps,
} from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { useCompaniesSA } from "@app/features/super-admin/api/super-admin.company.api";
import _debounce from "lodash/debounce";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const CompanyFilter = (props: SelectAutocompleteProps) => {
  const { t } = useTranslation();
  const [searchCompaniesQuery, setSearchCompaniesQuery] = useState<string>("");
  const companiesQuery = useCompaniesSA({
    keyword: searchCompaniesQuery,
    isPaying: true,
  });
  const companyChoices = (companiesQuery.data?.data ?? []).map((i) => ({
    value: i.id,
    label: i.name,
    id: i.id,
  }));

  const debouncedSearchCompany = _debounce((value: string) => {
    setSearchCompaniesQuery(value);
  }, 300);

  const onSearchCompanies = (value: string) => {
    debouncedSearchCompany(value);
  };

  return (
    <SelectAutocomplete
      loading={companiesQuery.isLoading}
      placeholder={t("Companies")}
      options={companyChoices}
      onSearch={onSearchCompanies}
      allowClear
      {...props}
    />
  );
};
