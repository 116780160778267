import {
  DeleteOutlined,
  InboxOutlined,
  LeftOutlined,
  PictureOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { UploadFileDef } from "@app/features/super-admin/types/super-admin.files.types";
import { Button, Col, Image, Popconfirm, Row, Space, Spin, Upload, UploadProps } from "antd";
import ImgCrop from "antd-img-crop";
import { RcFile } from "antd/lib/upload";
import { Dispatch, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageSizeEnum, getResizedImageUrl } from "../../../helpers/image.helper";
import { useFirebaseStorage } from "../../../hooks/useFirebaseStorage";
import { UploadedMedia } from "../../../types/media.types";
import styles from "./Uploader.module.scss";

const { Dragger } = Upload;

type UploaderProps = {
  existingMediaArray?: string[];
  multiple: boolean;
  showSetAsCoverImage?: boolean;
  allowCrop?: boolean;
  cropAspect?: CropAspectEnum;
  setHasChangedValues?: Dispatch<React.SetStateAction<boolean>>;
  onImagesChange?: (i: string[]) => void;
  onNewFileUploaded?: (f: UploadedMedia) => void;
};

export enum CropAspectEnum {
  COVER_IMAGE = 16 / 6,
  PROFILE_IMAGE = 1 / 1,
}

export const Uploader = ({
  setHasChangedValues,
  existingMediaArray,
  onImagesChange,
  multiple,
  showSetAsCoverImage = true,
  allowCrop = false,
  cropAspect = CropAspectEnum.PROFILE_IMAGE,
  onNewFileUploaded,
}: UploaderProps) => {
  const { t } = useTranslation();
  const { uploadPhoto } = useFirebaseStorage();
  const [uploadedFiles, setUploadedFiles] = useState<UploadFileDef[]>(
    existingMediaArray?.map((m) => ({
      url: m,
      uid: m,
      name: m,
    })) || []
  );
  const [visible, setVisible] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (onImagesChange) {
      const newImages = uploadedFiles.map((file) => file.url as string);
      if (JSON.stringify(newImages) !== JSON.stringify(existingMediaArray)) {
        onImagesChange(newImages);
      }
    }
  }, [uploadedFiles]);

  const uploaderProps: UploadProps = {
    accept: ".png,.gif,.jpg,.jpeg,.tif,.tiff,.webp",
    multiple: multiple,
    customRequest: async (input) => {
      setIsUploading(true);
      const { onError, onSuccess, file } = input;
      try {
        const result = await uploadPhoto(file as RcFile, (file as RcFile).name);
        setUploadedFiles((prev) => {
          if (multiple) {
            return [
              ...prev,
              {
                ...(file as RcFile),
                url: result.url,
                thumbUrl: result.url,
              },
            ];
          } else {
            return [
              {
                ...(file as RcFile),
                url: result.url,
                thumbUrl: result.url,
              },
            ];
          }
        });
        if (onNewFileUploaded) {
          onNewFileUploaded(result);
        }
        if (onSuccess) onSuccess(null);
      } catch (e) {
        console.error(e);
        if (onError) onError(e as Error, null);
      } finally {
        setIsUploading(false);
        if (setHasChangedValues) {
          setHasChangedValues(true);
        }
      }
    },
  };

  const removeImage = () => {
    const newUploadedFiles = uploadedFiles.filter((v, i) => i !== selectedImageIndex);
    setSelectedImageIndex((prev) => {
      if (newUploadedFiles.length <= 1) {
        return 0;
      } else {
        return prev === 0 ? 1 : prev - 1;
      }
    });
    setUploadedFiles(newUploadedFiles);
    if (setHasChangedValues) {
      setHasChangedValues(true);
    }
  };

  const changeCoverImage = (i: number) => {
    const newUploadedFiles = [...uploadedFiles];
    newUploadedFiles.splice(i, 1);
    const coverChangedFiles = [uploadedFiles[i], ...newUploadedFiles];
    setSelectedImageIndex(0);
    setUploadedFiles(coverChangedFiles);
    if (setHasChangedValues) {
      setHasChangedValues(true);
    }
  };

  const changeImage = (isNext: boolean) => () => {
    if (isNext) {
      setSelectedImageIndex(
        selectedImageIndex === uploadedFiles.length - 1 ? 0 : selectedImageIndex + 1
      );
    } else {
      setSelectedImageIndex(
        selectedImageIndex === 0 ? uploadedFiles.length - 1 : selectedImageIndex - 1
      );
    }
  };

  return (
    <>
      {uploadedFiles.length !== 0 && (
        <>
          <div className={styles.imageContainer}>
            <Image
              fallback={uploadedFiles[selectedImageIndex].url as string}
              placeholder={
                <div className={styles.imagePlaceholder}>
                  <Spin size="large" />
                </div>
              }
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "scale-down", maxHeight: "200px" }}
              className={styles.image}
              preview={{ visible: false }}
              src={getResizedImageUrl(uploadedFiles[selectedImageIndex].url, ImageSizeEnum.MEDIUM)}
              onClick={() => setVisible(true)}
            />
            {uploadedFiles.length > 1 && (
              <>
                {showSetAsCoverImage && selectedImageIndex === 0 && (
                  <div className={styles.coverImageLabel}>{t("Cover Image")}</div>
                )}
                <div className={styles.navigationLeft} onClick={changeImage(false)}>
                  <LeftOutlined className={styles.navigationIcon} />
                </div>
                <div className={styles.navigationRight} onClick={changeImage(true)}>
                  <RightOutlined className={styles.navigationIcon} />
                </div>
              </>
            )}
          </div>
          <div style={{ display: "none" }}>
            <Image.PreviewGroup
              preview={{
                visible,
                onVisibleChange: (vis) => setVisible(vis),
                current: selectedImageIndex,
              }}
            >
              {uploadedFiles.map((v, i) => (
                <Image key={i} src={v.url} style={{ border: "1px solid black", height: "100%" }} />
              ))}
            </Image.PreviewGroup>
          </div>
          <Row align="middle" justify="center" style={{ paddingTop: "5px" }}>
            {multiple && (
              <Col xs={24}>
                <Space direction="vertical" className={styles.uploadMoreButtons}>
                  <div className={styles.pagination}>
                    {`${selectedImageIndex + 1}/${uploadedFiles.length}`}
                  </div>
                  <Upload showUploadList={isUploading} {...uploaderProps}>
                    <Button type="primary" block ghost>
                      {t("Upload more")}
                    </Button>
                  </Upload>

                  <Popconfirm
                    title={t("Are you sure you want to delete this image?")}
                    okText={t("Yes")}
                    cancelText={t("No")}
                    onConfirm={removeImage}
                  >
                    <Button danger block ghost>
                      <DeleteOutlined /> {t("Remove image")}
                    </Button>
                  </Popconfirm>

                  {showSetAsCoverImage && (
                    <Button
                      disabled={selectedImageIndex === 0}
                      type="primary"
                      block
                      onClick={() => {
                        changeCoverImage(selectedImageIndex);
                      }}
                    >
                      <PictureOutlined /> {t("Set as cover image")}
                    </Button>
                  )}
                </Space>
              </Col>
            )}
            {!multiple && !allowCrop && (
              <div className={styles.uploadMoreButtons}>
                <Upload
                  showUploadList={{
                    showRemoveIcon: true,
                  }}
                  {...uploaderProps}
                >
                  <Button type="primary" block ghost>
                    {t("Replace")}
                  </Button>
                </Upload>
              </div>
            )}
            {!multiple && allowCrop && (
              <div className={styles.uploadMoreButtons}>
                <ImgCrop showGrid rotationSlider showReset aspect={cropAspect}>
                  <Upload maxCount={1} showUploadList={isUploading} {...uploaderProps}>
                    <Button type="primary" block ghost>
                      {t("Replace")}
                    </Button>
                  </Upload>
                </ImgCrop>
              </div>
            )}
          </Row>
        </>
      )}
      {uploadedFiles.length === 0 && allowCrop && (
        <ImgCrop showGrid rotationSlider showReset aspect={cropAspect}>
          <Dragger {...uploaderProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t("Select a file (JPG, PNG, GIF)")}</p>
            <p className="ant-upload-hint">{t("PNG, JPG, GIF up to 10MB")}</p>
          </Dragger>
        </ImgCrop>
      )}

      {uploadedFiles.length === 0 && !allowCrop && (
        <Dragger {...uploaderProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t("Select a file (JPG, PNG, GIF)")}</p>
          <p className="ant-upload-hint">{t("PNG, JPG, GIF up to 10MB")}</p>
        </Dragger>
      )}
    </>
  );
};
