import { CheckCircleOutlined, InfoCircleOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { DateFormats } from "@app/constants/date.constants";
import { useCandidatesSA } from "@app/features/super-admin/api/super-admin.candidate.api";
import {
  useTasksCompletedSA,
  useTasksSA,
} from "@app/features/super-admin/api/super-admin.task.api";
import { CandidateSADef } from "@app/features/super-admin/types/super-admin.candidate.types";
import { UserProgress } from "@app/features/super-admin/types/super-admin.progress.types";
import { ECandidateMethod, ECandidateStatus } from "@app/types/candidate.types";
import { Alert, Button, Card, Col, Row, Space, Spin, Tag, Tooltip, Typography } from "antd";
import uniqBy from "lodash/uniqBy";
import moment from "moment-timezone";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyModal } from "../../../../../../Companies/components/CompanyModal";
import { CandidateDrawer } from "../../../../../../components/CandidateDrawer/CandidateDrawer";
import { useJobModal } from "../../../../../../Jobs/JobModal/JobModal";
import { TaskGroup } from "./TaskGroup";

export const Goals = ({
  userProgress,
  invalidateQueries: propsInvalidateQueries,
}: {
  userProgress: UserProgress;

  invalidateQueries: () => void;
}) => {
  const { t } = useTranslation();
  const [expandedGoals, setExpandedGoals] = useState<string[]>([]);
  const [expandedCandidates, setExpandedCandidates] = useState<string[]>([]);
  const [showCandidateDrawer, setShowCandidateDrawer] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState<CandidateSADef | undefined>();

  const { setJobId } = useJobModal();
  const { setCompanyId } = useCompanyModal();

  const tasksQuery = useTasksSA();
  const completedTasksQuery = useTasksCompletedSA({
    workerProfileProgressId: userProgress.id,
  });

  const candidatesQuery = useCandidatesSA({
    workerProfileId: userProgress.workerProfileId,
  });

  const invalidateQueries = () => {
    return Promise.allSettled([completedTasksQuery.refetch(), propsInvalidateQueries()]);
  };

  const isLoadingTasks = tasksQuery.isLoading || completedTasksQuery.isLoading;
  const isErrorTasks = tasksQuery.isError || completedTasksQuery.isError;
  const allTasks = useMemo(
    () =>
      completedTasksQuery.data
        ? (tasksQuery.data ?? []).map((task) => ({
            ...task,
            completedTask: completedTasksQuery.data.find((item) => item.taskId === task.id),
          }))
        : [],
    [tasksQuery.data, completedTasksQuery.data]
  );
  const allGoals = useMemo(
    () =>
      uniqBy(allTasks, (task) => task.goal).map((task, index) => ({
        name: task.goal.replace(/_/g, " "),
        order: index,
        type: task.type,
        goal: task.goal,
      })),
    [allTasks]
  );

  const userGoals = useMemo(() => {
    return allGoals
      .filter((task) => task.type === "user")
      .map((goal, index) => {
        const isActive = userProgress.activeGoals.includes(goal.goal);
        const tasks = allTasks.filter((t) => t.goal === goal.goal);
        const status = isActive ? ("active" as const) : ("inactive" as const);
        const isCompleted = tasks.every((t) => t.completedTask);

        return {
          goal: goal.goal,
          name: goal.goal.replace(/_/g, " "),
          isCompleted,
          type: goal.type,
          order: index,
          status,
          tasks,
        };
      });
  }, [allTasks, allGoals, userProgress.activeGoals]);

  const candidatesWithGoals = useMemo(() => {
    return candidatesQuery.data?.data.map((candidate) => {
      const candidateProgress = userProgress.candidatesProgress.find(
        (progress) => progress.candidateId === candidate.id
      );

      const goals = allGoals
        .filter((task) => task.type === "candidate")
        .map((goal, index) => {
          const isActive = candidateProgress?.activeGoals.includes(goal.goal);
          const status = isActive ? ("active" as const) : ("inactive" as const);
          const tasks = allTasks
            .filter((t) => t.goal === goal.goal)
            .map((task) => ({
              ...task,
              completedTask: completedTasksQuery.data?.find(
                (item) =>
                  candidateProgress?.id === item.candidateProgressId && item.taskId === task.id
              ),
            }));
          const isCompleted = tasks.every((t) => t.completedTask);

          return {
            goal: goal.goal,
            name: goal.goal.replace(/_/g, " "),
            type: goal.type,
            order: index,
            status,
            tasks,
            isCompleted,
          };
        });

      return {
        ...candidate,
        goals,
        candidateProgressId: candidateProgress?.id,
      };
    });
  }, [candidatesQuery.data, allTasks, allGoals, userProgress.candidatesProgress]);

  return (
    <Row
      align="top"
      gutter={24}
      wrap={false}
      style={{
        flex: 1,
        overflowY: "auto",
      }}
    >
      {!!selectedCandidate && (
        <CandidateDrawer
          key={selectedCandidate.id}
          open={showCandidateDrawer}
          onClose={() => setShowCandidateDrawer(false)}
          candidate={selectedCandidate}
        />
      )}

      <Col
        flex="0 1 600px"
        style={{
          overflowY: "auto",
          height: "100%",
        }}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Space size="small" align="center">
            <UnorderedListOutlined style={{ fontSize: 20 }} />
            <Typography.Title level={5} style={{ marginBottom: "4px" }}>
              Applications ({candidatesQuery.data?.count ?? 0}):
            </Typography.Title>
          </Space>
          {candidatesQuery.isError && (
            <Alert showIcon message="Failed to load applications" type="error" />
          )}
          {candidatesQuery.isLoading && <Spin />}
          {candidatesQuery.data?.data.length === 0 && (
            <Alert showIcon message="No applications found" type="info" />
          )}

          {candidatesWithGoals?.map((candidate) => {
            const isExpanded = expandedCandidates.includes(candidate.id);
            const activeGoals = candidate.goals.filter((goal) => goal.status === "active");
            const goalsToShow = isExpanded ? candidate.goals : activeGoals;

            return (
              <Card key={candidate.id} size="small">
                <Row wrap={false}>
                  <Col flex="auto">
                    <Typography.Text
                      strong
                      style={{ cursor: "pointer" }}
                      onClick={() => setCompanyId(candidate.crafthuntCompany.id)}
                    >
                      {candidate.crafthuntCompany.name}
                    </Typography.Text>
                    {candidate.jobAds.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "8px",
                        }}
                      >
                        {candidate.jobAds.map((jobAd) => (
                          <Typography.Text
                            type="secondary"
                            key={jobAd.id}
                            onClick={() => setJobId(jobAd.id)}
                            style={{ cursor: "pointer" }}
                          >
                            {jobAd.title}
                          </Typography.Text>
                        ))}
                      </div>
                    )}

                    {goalsToShow.length > 0 && (
                      <Space direction="vertical">
                        {goalsToShow.map((goal) => {
                          const groupId = `${goal.goal}_${candidate.id}`;

                          return (
                            <TaskGroup
                              key={goal.goal}
                              group={goal}
                              userProgressId={userProgress.id}
                              candidateProgressId={candidate.candidateProgressId}
                              isExpanded={expandedGoals.includes(groupId)}
                              invalidateQueries={invalidateQueries}
                              setExpanded={(expanded) => {
                                setExpandedGoals((value) =>
                                  expanded
                                    ? [...value, groupId]
                                    : value.filter((id) => id !== groupId)
                                );
                              }}
                            />
                          );
                        })}
                      </Space>
                    )}
                    {candidate.goals.length > activeGoals.length && (
                      <div>
                        <Typography.Text
                          type="secondary"
                          style={{ fontSize: "12px", cursor: "pointer" }}
                          onClick={() => {
                            setExpandedCandidates((value) =>
                              isExpanded
                                ? value.filter((id) => id !== candidate.id)
                                : [...value, candidate.id]
                            );
                          }}
                        >
                          {isExpanded ? "Hide inactive goals" : "Show all goals"}
                        </Typography.Text>
                      </div>
                    )}
                  </Col>
                  <Col flex="none">
                    <Space align="end" direction="vertical" size="small">
                      <Typography.Text
                        type="secondary"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        {moment(candidate.createdAt).format(DateFormats.FULL_DATE_TIME)}
                      </Typography.Text>
                      <Tooltip placement="left" title="View application details">
                        <Button
                          type="text"
                          size="small"
                          icon={<InfoCircleOutlined />}
                          onClick={() => {
                            setShowCandidateDrawer(true);
                            setSelectedCandidate(candidate);
                          }}
                        >
                          Details
                        </Button>
                      </Tooltip>
                      <Tag style={{ margin: 0 }}>
                        Status:{" "}
                        {candidate.companyCandidateStatus
                          ? candidate.companyCandidateStatus.status === ECandidateStatus.CUSTOM
                            ? candidate.companyCandidateStatus.name
                            : t(candidate.companyCandidateStatus.name)
                          : "Unknown"}
                      </Tag>
                      <Tag style={{ margin: 0 }}>
                        Method:{" "}
                        {candidate.isCrafthuntRecommended
                          ? "Recommended"
                          : candidate.method === ECandidateMethod.APPLIED
                          ? "Applied"
                          : "Active Source"}
                      </Tag>
                      <Tag
                        style={{ margin: 0 }}
                        color={candidate.crafthuntCompany.isClaimed ? undefined : "red"}
                      >
                        {candidate.crafthuntCompany.isClaimed ? "Not scraped" : "Scraped"}
                      </Tag>
                    </Space>
                  </Col>
                </Row>
              </Card>
            );
          })}
        </Space>
      </Col>
      <Col
        flex="1 1 400px"
        style={{
          overflowY: "auto",
          height: "100%",
        }}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <div>
            <Space size="small" align="center">
              <CheckCircleOutlined style={{ fontSize: 20 }} />
              <Typography.Title level={5} style={{ marginBottom: "4px" }}>
                User goals:
              </Typography.Title>
            </Space>
          </div>
          <Space direction="vertical">
            {isLoadingTasks && <Spin />}
            {isErrorTasks && <Alert message="Failed to load tasks" type="error" />}
            {userGoals.map((group) => {
              const groupId = `${group.goal}_${userProgress.id}`;

              return (
                <TaskGroup
                  key={group.goal}
                  group={group}
                  userProgressId={userProgress.id}
                  isExpanded={expandedGoals.includes(groupId)}
                  invalidateQueries={invalidateQueries}
                  setExpanded={(expanded) => {
                    setExpandedGoals((value) =>
                      expanded ? [...value, groupId] : value.filter((id) => id !== groupId)
                    );
                  }}
                />
              );
            })}
          </Space>
        </Space>
      </Col>
    </Row>
  );
};
