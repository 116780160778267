import { Alert, Button, Descriptions, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { DateFormats } from "@app/constants/date.constants";
import { getLangName } from "@app/helpers/language.helper";
import { useSelector } from "react-redux";
import { RootState } from "@app/store/store";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { useCompanySA } from "@app/features/super-admin/api/super-admin.company.api";
import moment from "moment";
import { ExportOutlined } from "@ant-design/icons";
import { getJobPageUrl } from "@app/helpers/job-ads.helper";
import { getFullAddress } from "@app/helpers/address.helper";
import { useJobSA } from "@app/features/super-admin/api/super-admin.job.api";

export const InfoTab = ({ jobId }: { jobId: string }) => {
  const { t, i18n } = useTranslation();
  const jobTitles = useSelector((state: RootState) => state.tags.jobTitles);
  const availableTradesTags = useSelector((state: RootState) => state.tags.trades);

  const jobQuery = useJobSA({
    jobId: jobId,
  });
  const job = jobQuery.data;
  const companyQuery = useCompanySA({
    companyId: job?.crafthuntCompany.id,
  });

  const jobTitleChoices = jobTitles.map((i) => ({
    value: i.id,
    label: t(i.name),
    id: i.id,
  }));
  const tradesChoices = availableTradesTags.map((i) => ({
    value: i.id,
    label: t(i.name),
    id: i.id,
  }));

  return (
    <div>
      {companyQuery.isLoading && <LoadingSpinner />}
      {companyQuery.isError && <Alert message={"Company failed to fetch"} type="error" />}

      {companyQuery.data && (
        <Form layout="vertical">
          <Form.Item label="Company internal comment">
            <Input.TextArea
              autoSize
              disabled
              defaultValue={companyQuery.data.internalComment}
              style={{
                background: "#fff",
                color: "black",
              }}
            />
          </Form.Item>
        </Form>
      )}

      {jobQuery.isLoading && <LoadingSpinner />}
      {jobQuery.isError && <Alert message={"Job failed to fetch"} type="error" />}

      {job && (
        <Descriptions bordered column={2} title="Job Info" size="small">
          <Descriptions.Item span={2} label="Job ID">
            {job.slug}{" "}
            <Button
              type="link"
              rel="noreferrer"
              target="_blank"
              icon={<ExportOutlined />}
              href={getJobPageUrl(job)}
              style={{ padding: 0, marginLeft: "20px" }}
            >
              Job link
            </Button>
          </Descriptions.Item>
          <Descriptions.Item label="Role">
            {jobTitleChoices.find((item) => item.value === job.roleId)?.label}
          </Descriptions.Item>
          <Descriptions.Item label="Trade">
            {tradesChoices.find((item) => item.value === job.tradeId)?.label}
          </Descriptions.Item>
          <Descriptions.Item label="Address" span={2}>
            {job.address && getFullAddress(job.address)}
          </Descriptions.Item>
          <Descriptions.Item label="Use company address" span={2}>
            {job.useCompanyAddress ? "Yes" : "No"}
          </Descriptions.Item>
          <Descriptions.Item label="Required languages">
            {job.requiredLanguages
              ?.map((lang) => getLangName(i18n.language, lang.value))
              .join(", ")}
          </Descriptions.Item>
          <Descriptions.Item label="Allowed countries">
            {job.requiredCountries
              ?.map((country) => getLangName(i18n.language, country.value, "region"))
              .join(", ")}
          </Descriptions.Item>
          <Descriptions.Item label="Travel">{t(`${job.travel}-travel`)}</Descriptions.Item>
          <Descriptions.Item label="Project types">
            {job.projectTypes.map((item) => t(item.name)).join(", ")}
          </Descriptions.Item>
          <Descriptions.Item label="Description" span={2}>
            <span style={{ whiteSpace: "pre-wrap" }}>{job.description}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Job start date">
            {job.jobStartDate && moment(job.jobStartDate).format(DateFormats.D_M_Y)}
          </Descriptions.Item>
          <Descriptions.Item label="Job end date">
            {job.jobEndDate && moment(job.jobStartDate).format(DateFormats.D_M_Y)}
          </Descriptions.Item>
          <Descriptions.Item label="Duration">{t(`${job.duration}-duration`)}</Descriptions.Item>
          <Descriptions.Item label="Hours per week">{job.workingHours}</Descriptions.Item>
          <Descriptions.Item label="Salary minimum">{job.salaryMin}</Descriptions.Item>
          <Descriptions.Item label="Salary maximum">{job.salaryMax}</Descriptions.Item>
          <Descriptions.Item label="Currency">{job.currency}</Descriptions.Item>
          <Descriptions.Item label="Frequency">
            {t(`${job.salaryFrequency}-salary-frequency`)}
          </Descriptions.Item>
          <Descriptions.Item label="Salary hidden">
            {job.salaryHidden ? "Yes" : "No"}
          </Descriptions.Item>
        </Descriptions>
      )}
    </div>
  );
};
