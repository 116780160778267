import {
  CompanyCandidateStatusDef,
  ECandidateApprovalAction,
  ECandidateMethod,
  ECandidateRejectionReason,
  ECandidateStatus,
} from "@app/types/candidate.types";
import { PaginationRequestDef, PaginationResponseDef } from "../../../types/pagination.types";

export interface CandidatesSAColumnsDataDef {
  key: string;
  isScrapedJob: JSX.Element;
  companyName: JSX.Element;
  jobAds: {
    id: string;
    title: string;
    slug: string;
    fullSlug: string;
  }[];
  jobName: JSX.Element | string;
  candidateName: JSX.Element | string;
  status: string;
  method: string;
  createdAt: JSX.Element;
  operation: JSX.Element;
}

export interface CandidateSADef {
  id: string;
  companyCandidateStatus?: CompanyCandidateStatusDef;
  jobAds: [
    {
      id: string;
      title: string;
      slug: string;
      fullSlug: string;
    }
  ];
  workerProfile: {
    id: string;
    firstName: string;
    lastName: string;
    slug: string;
    phone?: string;
    email?: string;
    deletedAt: string | null;
  };
  crafthuntCompany: {
    id: string;
    name: string;
    slug: string;
    isClaimed: boolean;
  };
  isCrafthuntRecommended: boolean;
  method: ECandidateMethod;
  createdAt: string;
}

export type GetCandidatesSAParamsDef = PaginationRequestDef & {
  companyId?: string;
  workerProfileId?: string;
  status?: ECandidateStatus;
  excludedStatuses?: ECandidateStatus[];
};

export type CandidatesSAWithPaginationDef = PaginationResponseDef<CandidateSADef>;

export type GetCandidateApprovalSAParamsDef = PaginationRequestDef & {
  workerProfileId?: string;
  startDate?: string;
  endDate?: string;
};

export type CandidateApprovalSADef = {
  id: string;
  action: ECandidateApprovalAction;
  rejectionReason?: ESuperAdminCandidateRejectionReason;
  description: string;
  candidate: CandidateSADef;
  candidateId: string;
  superAdminId: string;
  createdAt: string;
};

export type CandidateApprovalsSAWithPaginationDef = PaginationResponseDef<CandidateApprovalSADef>;

export const ESuperAdminCandidateRejectionReason = {
  ...ECandidateRejectionReason,
  APPLIED_TO_SCRAPED_JOB: "rejection-reason-applied-to-scraped-job",
} as const;
export type ESuperAdminCandidateRejectionReason =
  (typeof ESuperAdminCandidateRejectionReason)[keyof typeof ESuperAdminCandidateRejectionReason];
