import { CheckOutlined, CloseOutlined, ExportOutlined } from "@ant-design/icons";
import { PhoneNumberInput } from "@app/components/ui/PhoneNumberInput/PhoneNumberInput";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { ENV } from "@app/constants/env";
import { CompanySADef } from "@app/features/super-admin/types/super-admin.company.types";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { ApprovalStatusEnum } from "@app/types/company-info.types";
import { Button, Form, Input, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { isPossiblePhoneNumber } from "react-phone-number-input";

export const CompanyForm = ({ company }: { company: CompanySADef }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Button
        type="link"
        rel="noreferrer"
        target="_blank"
        icon={<ExportOutlined />}
        href={`${ENV.WEB_URL}/${convertLangForBackend(i18n.language)}/companies/${company.slug}`}
        style={{ marginBottom: "10px", padding: 0 }}
      >
        Profile link
      </Button>
      <Form.Item label="ID" name="id">
        <Input disabled />
      </Form.Item>
      <Form.Item
        label={t("Phone number")}
        name={"contactPhone"}
        rules={[
          {
            type: "string",
            required: true,
            whitespace: true,
            message: t("Required"),
          },
          {
            validator: (_, value) => {
              if (!isPossiblePhoneNumber(value)) {
                return Promise.reject(t("Not valid phone number"));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <PhoneNumberInput placeholder={t("Enter phone number")} />
      </Form.Item>
      <Form.Item
        label={t("Approval status")}
        name={"approvalStatus"}
        rules={[
          {
            required: true,
            whitespace: true,
            message: t("Required"),
          },
        ]}
      >
        <SelectAutocomplete
          placeholder={t("Select")}
          options={Object.values(ApprovalStatusEnum).map((item) => ({
            value: item,
            label: item,
          }))}
          sortAlphabetically={false}
        />
      </Form.Item>
      <Form.Item
        label={t("Has paid for job ads")}
        name={"hasPaidForJobAds"}
        valuePropName="checked"
      >
        <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
      </Form.Item>
      <Form.Item
        label={t("hasManualCandidateApproval")}
        name={"hasManualCandidateApproval"}
        valuePropName="checked"
      >
        <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
      </Form.Item>
      <Form.Item
        label={t("Comment")}
        name={"internalComment"}
        help={t("Internal comment for us, this is not shared with the company")}
      >
        <Input.TextArea />
      </Form.Item>
    </>
  );
};
