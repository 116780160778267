import { ClockCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { DateFormats } from "@app/constants/date.constants";
import {
  createReminderSA,
  deleteReminderSA,
  useRemindersSA,
} from "@app/features/super-admin/api/super-admin.reminder.api";
import { RootState } from "@app/store/store";
import { useMutation } from "@tanstack/react-query";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Popover,
  Row,
  Space,
  Typography,
} from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment-timezone";
import { useState } from "react";
import { useSelector } from "react-redux";

export const RemindersList = ({ workerProfileProgressId }: { workerProfileProgressId: string }) => {
  const remindersQuery = useRemindersSA({
    workerProfileProgressId,
  });

  const deleteReminder = useMutation({
    mutationFn: deleteReminderSA,
    onSettled: () => {
      remindersQuery.refetch();
    },
    onError: () => {
      message.error("Sorry, something went wrong.");
    },
  });

  return (
    <>
      {remindersQuery.isError && <Alert message="Failed to load reminders" type="error" />}
      {remindersQuery.data?.length !== 0 && (
        <Space direction="vertical" style={{ width: "100%", paddingBottom: "16px" }}>
          {remindersQuery.data?.map((reminder) => (
            <Alert
              key={reminder.id}
              message={
                <Row wrap={false}>
                  <Col flex="1">
                    <div>
                      <Typography.Text
                        type="secondary"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        <ClockCircleOutlined style={{ marginRight: "4px" }} />
                        {moment(reminder.date).format(DateFormats.FULL_DATE_TIME)}
                      </Typography.Text>
                    </div>
                    <Typography.Text style={{ whiteSpace: "pre-line" }}>
                      {reminder.description}
                    </Typography.Text>
                  </Col>
                  <Col flex="none">
                    <Button
                      icon={<DeleteOutlined />}
                      size="small"
                      type="text"
                      loading={deleteReminder.isPending}
                      onClick={() => {
                        Modal.confirm({
                          title: "Are you sure you want to delete this reminder?",
                          onOk: () => {
                            deleteReminder.mutate({
                              id: reminder.id,
                            });
                          },
                        });
                      }}
                    />
                  </Col>
                </Row>
              }
            />
          ))}
        </Space>
      )}
    </>
  );
};

const lastSelectableDate = moment().add(120, "day");
const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  return current && (current < moment().startOf("day") || current > lastSelectableDate);
};

export const RemindMeButton = ({
  workerProfileProgressId,
  defaultDescription,
}: {
  workerProfileProgressId: string;
  defaultDescription: string;
}) => {
  const [form] = Form.useForm<{
    date: moment.Moment;
    description: string;
  }>();
  const [isOpen, setIsOpen] = useState(false);
  const parsedSuperToken = useSelector((state: RootState) => state.superAdmin.parsedSuperToken);

  const remindersQuery = useRemindersSA({
    workerProfileProgressId,
  });

  const createReminder = useMutation({
    mutationFn: createReminderSA,
    onSettled: () => {
      remindersQuery.refetch();
    },
    onSuccess: () => {
      setIsOpen(false);
      form.resetFields();
    },
    onError: () => {
      message.error("Sorry, something went wrong.");
    },
  });

  const content = (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        description: defaultDescription,
      }}
      onFinish={(values) => {
        if (parsedSuperToken) {
          createReminder.mutate({
            superAdminId: parsedSuperToken.id,
            workerProfileProgressId,
            date: values.date.toISOString(),
            description: values.description,
          });
        }
      }}
    >
      <Space direction="vertical">
        <Form.Item
          label="Date"
          name="date"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker showTime showSecond={false} disabledDate={disabledDate} />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input.TextArea rows={4} placeholder="Enter..." />
        </Form.Item>
        <Form.Item noStyle>
          <Button
            type="primary"
            htmlType="submit"
            loading={createReminder.isPending}
            style={{
              width: "100%",
            }}
          >
            Create
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );

  return (
    <Popover
      title="Create a reminder"
      trigger="click"
      content={content}
      open={isOpen}
      onOpenChange={(newOpen) => {
        setIsOpen(newOpen);

        if (!newOpen) {
          form.resetFields();
        }
      }}
    >
      <Button>🔔 Remind me</Button>
    </Popover>
  );
};
