import { DateFormats } from "@app/constants/date.constants";
import { useCandidatesSA } from "@app/features/super-admin/api/super-admin.candidate.api";
import { SUPER_ADMIN_PAGE_SIZE } from "@app/features/super-admin/constants/super-admin.constants";
import {
  CandidateSADef,
  CandidatesSAColumnsDataDef,
} from "@app/features/super-admin/types/super-admin.candidate.types";
import { UserFormDef } from "@app/features/super-admin/types/super-admin.user.types";
import { ECandidateMethod, ECandidateStatus } from "@app/types/candidate.types";
import { Col, Row, Space, Table, TableColumnsType, Tag, Typography } from "antd";
import { TablePaginationConfig } from "antd/es/table/interface";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactMoment from "react-moment";
import { useCompanyModal } from "../Companies/components/CompanyModal";
import { CandidateDrawer } from "../components/CandidateDrawer/CandidateDrawer";
import { CompanyFilter } from "../Filters/CompanyFilter/CompanyFilter";
import { UserFilter } from "../Filters/UserFilter/UserFilter";
import { useJobModal } from "../Jobs/JobModal/JobModal";
import { useUserModalGlobal } from "../Users/components/UserModal/UserModalGlobal";

type Props = {
  selectedUser?: UserFormDef;
  excludedStatuses?: ECandidateStatus[];
};

export const Candidates = ({ selectedUser, excludedStatuses }: Props) => {
  const { t } = useTranslation();
  const { openUserModal } = useUserModalGlobal();
  const { setJobId } = useJobModal();
  const { setCompanyId } = useCompanyModal();
  const [showCandidateDrawer, setShowCandidateDrawer] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState<CandidateSADef | undefined>();
  const [page, setPage] = useState(0);

  // filter params
  const [companyIdForFilter, setCompanyIdForFilter] = useState<string>();
  const [userIdForFilter, setUserIdForFilter] = useState<string | undefined>(selectedUser?.id);
  const showFilters = !selectedUser;

  const candidatesQuery = useCandidatesSA({
    limit: SUPER_ADMIN_PAGE_SIZE,
    offset: page,
    companyId: companyIdForFilter,
    workerProfileId: userIdForFilter,
    excludedStatuses,
  });

  const handlePageChange = async (pagination: TablePaginationConfig) => {
    if (typeof pagination.current === "number" && typeof pagination.pageSize === "number") {
      setPage(pagination.current - 1);
    }
  };

  const generatedData = (data: CandidateSADef[]): CandidatesSAColumnsDataDef[] => {
    return data?.map((item) => ({
      key: item.id,
      isScrapedJob: (
        <Tag color={item.crafthuntCompany.isClaimed ? "green" : "red"}>
          {item.crafthuntCompany.isClaimed ? t("No") : t("Yes")}
        </Tag>
      ),
      companyName: (
        <a onClick={() => setCompanyId(item.crafthuntCompany.id)}>{item.crafthuntCompany.name}</a>
      ),
      jobAds: item.jobAds,
      jobName:
        item.jobAds.length === 1 ? (
          <a onClick={() => setJobId(item.jobAds[0].id)}>{item.jobAds[0].title}</a>
        ) : item.jobAds.length > 1 ? (
          ` ${item.jobAds.length} ${t("Job ads")}`
        ) : (
          ""
        ),
      candidateName:
        showFilters && !item.workerProfile.deletedAt ? (
          <a
            onClick={() => {
              openUserModal({ userId: item.workerProfile.id });
            }}
          >
            {[item.workerProfile.firstName, item.workerProfile.lastName].join(" ")}
          </a>
        ) : (
          [item.workerProfile.firstName, item.workerProfile.lastName].join(" ")
        ),
      status:
        item.companyCandidateStatus?.status === ECandidateStatus.CUSTOM
          ? item.companyCandidateStatus.name
          : t(item.companyCandidateStatus?.name || ""),
      method: item.isCrafthuntRecommended
        ? "Recommended"
        : item.method === ECandidateMethod.APPLIED
        ? "Applied"
        : "Active Source",
      createdAt: (
        <ReactMoment withTitle titleFormat={DateFormats.FULL_DATE_TIME} fromNow>
          {item.createdAt}
        </ReactMoment>
      ),
      operation: (
        <a
          onClick={() => {
            setSelectedCandidate(item);
            setShowCandidateDrawer(true);
          }}
        >
          {t("Details")}
        </a>
      ),
    }));
  };

  const columns: TableColumnsType<CandidatesSAColumnsDataDef> = [
    {
      title: "Scraped",
      dataIndex: "isScrapedJob",
      key: "isScrapedJob",
      ellipsis: true,
      width: 100,
    },
    {
      title: t("Company name"),
      dataIndex: "companyName",
      key: "companyName",
      ellipsis: true,
      width: 250,
    },
    { title: t("Job ads"), dataIndex: "jobName", key: "jobName", ellipsis: true, width: 250 },
    {
      title: t("candidate-name-label"),
      dataIndex: "candidateName",
      key: "candidateName",
      ellipsis: true,
      width: 200,
    },
    { title: t("Status"), dataIndex: "status", key: "status", ellipsis: true, width: 150 },
    { title: "Method", dataIndex: "method", key: "method", ellipsis: true, width: 150 },
    { title: t("Created at"), dataIndex: "createdAt", key: "createdAt" },
    { title: t("Actions"), dataIndex: "operation", key: "operation", fixed: "right", width: 100 },
  ];

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      {showFilters && (
        <Row gutter={[16, 16]} align="middle">
          <Col span={12}>
            <CompanyFilter
              onChange={(v) => {
                setPage(0);
                setCompanyIdForFilter(v);
              }}
            />
          </Col>
          <Col span={12}>
            <UserFilter
              value={userIdForFilter}
              onChange={(v) => {
                setPage(0);
                setUserIdForFilter(v);
              }}
            />
          </Col>
        </Row>
      )}
      <Table
        style={{ width: "100%" }}
        columns={columns}
        loading={candidatesQuery.isLoading}
        dataSource={generatedData(candidatesQuery.data?.data ?? [])}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <Typography.Text>{t("Job ads")}</Typography.Text>
              <ul>
                {record.jobAds.map((job) => (
                  <li key={job.id}>
                    <a onClick={() => setJobId(job.id)}>{job.title}</a>
                  </li>
                ))}
              </ul>
            </>
          ),
          rowExpandable: (record) => record.jobAds.length > 1,
        }}
        pagination={{
          current: page + 1,
          total: candidatesQuery.data?.count,
          showTotal: (total) => `${t("Total")} ${total} ${t("applications-label")}`,
          pageSize: SUPER_ADMIN_PAGE_SIZE,
          showSizeChanger: false,
          position: ["bottomLeft"],
        }}
        onChange={handlePageChange}
        scroll={{ x: 1400 }}
      />
      {!!selectedCandidate && (
        <CandidateDrawer
          key={selectedCandidate.id}
          open={showCandidateDrawer}
          onClose={() => setShowCandidateDrawer(false)}
          candidate={selectedCandidate}
        />
      )}
    </Space>
  );
};
