import { RoutePaths } from "@app/features/routes/types/routes.types";
import { Dispatch, RootState } from "@app/store/store";
import { ECandidateStatus } from "@app/types/candidate.types";
import { ContentLayout } from "@layouts/ContentLayout/ContentLayout";
import { Tabs, TabsProps } from "antd";
import clsx from "clsx";
import { parseAsStringEnum, useQueryState } from "nuqs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMount } from "react-use";
import SuperAdminPasswordModal from "../SuperAdminPasswordModal/SuperAdminPasswordModal";
import { Approvals } from "./components/Approvals/Approvals";
import { Candidates } from "./components/Candidates/Candidates";
import { Companies } from "./components/Companies/Companies";
import { CompanyModal } from "./components/Companies/components/CompanyModal";
import { Dashboard } from "./components/Dashboard/Dashboard";
import { JobModal } from "./components/Jobs/JobModal/JobModal";
import { Jobs } from "./components/Jobs/Jobs";
import { UserProgress } from "./components/UserProgress/UserProgress";
import { UserModalGlobal } from "./components/Users/components/UserModal/UserModalGlobal";
import { Users } from "./components/Users/Users";
import styles from "./SuperAdminStuff.module.scss";

enum TAB_KEYS {
  DASHBOARD = "dashboard",
  COMPANIES = "companies",
  PROGRESS = "progress",
  USERS = "users",
  JOBS = "jobs",
  CANDIDATES = "candidates",
  APPROVALS = "approvals",
}

export const SuperAdminStuff = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [approvalsCount, setApprovalsCount] = useState(0);
  const loadingSuperAdminCheck = useSelector(
    (state: RootState) => state.loading.effects.superAdmin.superAdminCheck
  );
  const isSuperAdmin = useSelector((state: RootState) => state.auth.parsedToken?.isSuperAdmin);
  const superToken = useSelector((state: RootState) => state.superAdmin.superToken);
  const dispatch = useDispatch<Dispatch>();
  const [activeTabKey, setActiveTabKey] = useQueryState(
    "tab",
    parseAsStringEnum(Object.values(TAB_KEYS)).withDefault(TAB_KEYS.DASHBOARD).withOptions({
      history: "push",
    })
  );
  // modals
  const [superAdminKeyModalOpen, setSuperAdminKeyModalOpen] = useState(false);

  const redirectToDashboard = () => {
    navigate(RoutePaths.DASHBOARD);
  };

  useMount(() => {
    if (superToken) {
      dispatch.tags.getAllCertificates();
    }
  });

  useEffect(() => {
    if (!loadingSuperAdminCheck && !superToken) {
      if (isSuperAdmin) {
        setSuperAdminKeyModalOpen(true);
      } else {
        redirectToDashboard();
      }
    }
  }, [loadingSuperAdminCheck, isSuperAdmin, superToken]);

  const tabItems: TabsProps["items"] = [
    {
      key: TAB_KEYS.DASHBOARD,
      label: "Dashboard",
      children: <Dashboard />,
      className: styles.fullHeightTab,
    },
    {
      key: TAB_KEYS.PROGRESS,
      label: "User Progress",
      children: <UserProgress />,
      className: clsx(styles.fullHeightTab, styles.flexTab),
    },
    {
      key: TAB_KEYS.COMPANIES,
      label: t("Companies"),
      children: <Companies />,
    },
    {
      key: TAB_KEYS.USERS,
      label: t("Users"),
      children: <Users />,
    },
    {
      key: TAB_KEYS.JOBS,
      label: t("Job ads"),
      children: <Jobs />,
    },
    {
      key: TAB_KEYS.CANDIDATES,
      label: t("applications-label"),
      children: <Candidates excludedStatuses={[ECandidateStatus.NEEDS_APPROVAL]} />,
    },
    {
      key: TAB_KEYS.APPROVALS,
      label: t("approvals-label") + ` (${approvalsCount})`,
      children: (
        <Approvals
          isActive={activeTabKey === TAB_KEYS.APPROVALS}
          onCountChange={setApprovalsCount}
        />
      ),
      forceRender: true,
    },
  ];

  return (
    <>
      <ContentLayout fullHeight>
        {superToken && (
          <Tabs
            destroyInactiveTabPane
            size="small"
            activeKey={activeTabKey}
            onChange={(tabKey) => setActiveTabKey(tabKey as TAB_KEYS)}
            items={tabItems}
            style={{ width: "100%", flex: 1 }}
            className={clsx(
              styles.tabs,
              activeTabKey === TAB_KEYS.DASHBOARD && styles.fullHeight,
              activeTabKey === TAB_KEYS.PROGRESS && styles.fullHeightScrollInside
            )}
          />
        )}
      </ContentLayout>
      <UserModalGlobal />
      <CompanyModal />
      <JobModal />
      <SuperAdminPasswordModal
        open={superAdminKeyModalOpen}
        setOpen={setSuperAdminKeyModalOpen}
        onCancel={redirectToDashboard}
      />
    </>
  );
};
