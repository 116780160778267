import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import {
  CVParserProcessSAWithPaginationDef,
  GetCVParserProcessSAParamsDef,
} from "../types/super-admin.cv-parser.types";
import { queryKeysSA } from "./queryKeys";
import { superAdminApi } from "./super-admin.api";

export const getCVParserProcessSA = (params: GetCVParserProcessSAParamsDef) => {
  return superAdminApi.get<CVParserProcessSAWithPaginationDef>("/super_admin/cv_parser", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "brackets" });
    },
  });
};

export const useCVParserProcessSA = (
  params: GetCVParserProcessSAParamsDef,
  options?: {
    enabled?: boolean;
    refetchInterval?: number;
  }
) =>
  useQuery({
    enabled: options?.enabled ?? true,
    queryKey: [...queryKeysSA.lists(), "cv-parser-process", params] as const,
    queryFn: ({ queryKey }) => getCVParserProcessSA(queryKey[3]).then(({ data }) => data),
    refetchInterval: options?.refetchInterval,
  });
