import { useQuery } from "@tanstack/react-query";
import { queryKeysSA } from "./queryKeys";
import { superAdminApi } from "./super-admin.api";

type Reminder = {
  createdAt: string;
  date: string;
  description: string;
  id: string;
  superAdminId: string;
  workerProfileProgressId: string;
};

export const useRemindersSA = (params?: {
  workerProfileProgressId?: string;
  superAdminId?: string;
}) =>
  useQuery({
    queryKey: [...queryKeysSA.lists(), "reminders", params] as const,
    queryFn: ({ queryKey }) =>
      superAdminApi
        .get<Reminder[]>("/super_admin/reminders", {
          params: queryKey[3],
        })
        .then(({ data }) => data),
  });

export const createReminderSA = (params: {
  superAdminId: string;
  workerProfileProgressId: string;
  date: string;
  description: string;
}) => {
  return superAdminApi.post(`/super_admin/reminders`, params);
};

export const deleteReminderSA = (params: { id: string }) => {
  return superAdminApi.delete(`/super_admin/reminders/${params.id}`);
};
