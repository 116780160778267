import { launchSync, useGetCompanyIntegrationSyncs } from "@app/api/company-integration-syncs.api";
import { Button } from "@app/components/ui/Button/Button";
import { Colors } from "@app/constants/colors.constants";
import { ECompanyIntegrationSyncStatus } from "@app/types/company-integration-sync.types";
import { useMutation } from "@tanstack/react-query";
import { Divider, Progress, Spin } from "antd";

type CompanyIntegrationSyncProps = {
  companyIntegrationId: string;
};

export const CompanyIntegrationSync = ({ companyIntegrationId }: CompanyIntegrationSyncProps) => {
  const companyIntegrationQuery = useGetCompanyIntegrationSyncs(
    { companyIntegrationId, limit: 1 },
    {
      refetchInterval: 1000,
    }
  );
  const canLaunch =
    companyIntegrationQuery.data?.data?.[0]?.status !== ECompanyIntegrationSyncStatus.PROCESSING;
  const hasData = companyIntegrationQuery.data?.data?.length;
  const lastSync = companyIntegrationQuery.data?.data?.[0];
  const totalToProcess = lastSync?.totalToProcess || 0;
  const totalProcessed = lastSync?.totalProcessed || 0;
  const progress = (totalProcessed / totalToProcess) * 100;

  const getStatus = () => {
    if (lastSync?.status === ECompanyIntegrationSyncStatus.PROCESSING) {
      return "active";
    } else if (lastSync?.status === ECompanyIntegrationSyncStatus.FAILED) {
      return "exception";
    }
    return "success";
  };

  const handleLaunchSync = useMutation({
    mutationFn: () => {
      return launchSync(companyIntegrationId);
    },
    onSettled: () => {
      companyIntegrationQuery.refetch();
    },
  });

  return (
    <>
      <p>Crafthunt automatically synchronize your jobs once a day</p>
      {companyIntegrationQuery.isLoading && <Spin />}
      {!companyIntegrationQuery.isLoading && canLaunch && (
        <Button type="primary" onClick={() => handleLaunchSync.mutate()}>
          Sync jobs now
        </Button>
      )}

      {hasData && (
        <>
          <Divider />
          <Progress percent={progress} status={getStatus()} />
          <p>
            Jobs synced: {totalProcessed} / {totalToProcess}
          </p>
          {!!lastSync?.failedIntegrationJobAdIds?.length && (
            <p style={{ color: Colors.red }}>Errors: {lastSync.failedIntegrationJobAdIds.length}</p>
          )}
        </>
      )}
    </>
  );
};
