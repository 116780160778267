import { useQuery } from "@tanstack/react-query";
import { CompletedTask, Task } from "../types/super-admin.task.types";
import { queryKeysSA } from "./queryKeys";
import { superAdminApi } from "./super-admin.api";

export const useTasksSA = (params?: { goal?: string; type?: "user" | "candidate" }) =>
  useQuery({
    staleTime: 1000 * 60 * 60,
    queryKey: [...queryKeysSA.lists(), "tasks", params] as const,
    queryFn: ({ queryKey }) =>
      superAdminApi
        .get<Task[]>("/super_admin/tasks", {
          params: queryKey[3],
        })
        .then(({ data }) => data),
  });

export const useTasksCompletedSA = (params: {
  workerProfileProgressId?: string | null;
  candidateProgressId?: string | null;
}) =>
  useQuery({
    enabled: Boolean(params.workerProfileProgressId || params.candidateProgressId),
    queryKey: [...queryKeysSA.lists(), "tasks-completed", params] as const,
    queryFn: ({ queryKey }) =>
      superAdminApi
        .get<CompletedTask[]>("/super_admin/tasks_completed", {
          params: queryKey[3],
        })
        .then(({ data }) => data),
  });

export const createCompletedTaskSA = (params: {
  taskId: string;
  workerProfileProgressId?: string;
  candidateProgressId?: string;
}) => {
  return superAdminApi.post(`/super_admin/tasks_completed`, params);
};

export const deleteCompletedTaskSA = (params: { completedTaskId: string }) => {
  return superAdminApi.delete(`/super_admin/tasks_completed/${params.completedTaskId}`);
};
