import { Select, SelectProps } from "antd";
import clsx from "clsx";
import styles from "./SelectAutocomplete.module.scss";

export type SelectAutocompleteProps = {
  sortAlphabetically?: boolean;
} & SelectProps;

export const SelectAutocomplete = ({
  sortAlphabetically = true,
  className,
  ...rest
}: SelectAutocompleteProps) => {
  return (
    <Select
      style={{ width: "100%" }}
      showSearch
      dropdownMatchSelectWidth={false}
      filterOption={(input, option) =>
        ((typeof option?.label === "string" && option.label) || "")
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      size="large"
      filterSort={(a, b) => {
        if (sortAlphabetically && typeof a.label === "string" && typeof b.label === "string") {
          return a.label.localeCompare(b.label);
        }
        return 0;
      }}
      className={clsx(styles.select, className)}
      {...rest}
    />
  );
};
