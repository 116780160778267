import clsx from "clsx";
import { memo } from "react";
import { AnimatePresence, motion } from "motion/react";
import { UserOutlined } from "@ant-design/icons";
import {
  UserProgress,
  UserProgressWithPaginationDef,
} from "@app/features/super-admin/types/super-admin.progress.types";
import { UseQueryResult } from "@tanstack/react-query";
import { Avatar, Badge, Card, Empty, Spin, Tag, Tooltip, Typography } from "antd";

import styles from "./UserList.module.scss";

export const UserList = ({
  userProgressId,
  setUserProgressId,
  usersProgressQuery,
}: {
  userProgressId: string | null;
  setUserProgressId: (value: string | null) => void;
  usersProgressQuery: UseQueryResult<UserProgressWithPaginationDef, Error>;
}) => {
  return (
    <>
      {usersProgressQuery.isLoading && <Empty description={<Spin />} />}
      {usersProgressQuery.isError && (
        <Empty description={<Typography.Text type="danger">Error loading data</Typography.Text>} />
      )}
      {usersProgressQuery.data && usersProgressQuery.data.data.length === 0 && (
        <Empty description="No data" />
      )}
      {usersProgressQuery.data && usersProgressQuery.data.data.length > 0 && (
        <Typography.Text type="secondary" className={styles.header}>
          {usersProgressQuery.data.totalPages > 1 && (
            <>Top {usersProgressQuery.data.data.length} of</>
          )}{" "}
          {usersProgressQuery.data.count} user{usersProgressQuery.data.count > 1 ? "s" : ""}
        </Typography.Text>
      )}
      <AnimatePresence initial={false}>
        {usersProgressQuery.data?.data?.map((item) => {
          return (
            <motion.div
              key={item.id}
              layout
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
            >
              <Item
                userProgress={item}
                isActive={userProgressId === item.id}
                onClick={setUserProgressId}
              />
            </motion.div>
          );
        })}
      </AnimatePresence>
    </>
  );
};

const Item = memo(
  ({
    userProgress,
    isActive,
    onClick,
  }: {
    userProgress: UserProgress;
    isActive: boolean;
    onClick: (id: string | null) => void;
  }) => {
    return (
      <Badge.Ribbon text={<>{userProgress.totalPoints}</>}>
        <Card
          size="small"
          className={clsx(styles.card, isActive && styles.active)}
          onClick={() => {
            onClick(isActive ? null : userProgress.id);
          }}
        >
          <Avatar size="small" src={userProgress.workerProfile.picture} icon={<UserOutlined />} />{" "}
          <Typography.Text className={styles.userName}>
            {userProgress.workerProfile.firstName} {userProgress.workerProfile.lastName}{" "}
          </Typography.Text>
          {userProgress.currentTask && (
            <Tooltip title={userProgress.currentTask.name}>
              <Tag className={styles.tag}>
                <span className={styles.tagText}>{userProgress.currentTask.name}</span>
              </Tag>
            </Tooltip>
          )}
        </Card>
      </Badge.Ribbon>
    );
  }
);
