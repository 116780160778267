import { PaginationRequestDef } from "@app/types/pagination.types";
import { useQuery } from "@tanstack/react-query";
import { UserProgress, UserProgressWithPaginationDef } from "../types/super-admin.progress.types";
import { queryKeysSA } from "./queryKeys";
import { superAdminApi } from "./super-admin.api";

export const progressQueryKey = [...queryKeysSA.all, "users-progress"] as const;

export enum EUserProgressType {
  ACTIVE = "active",
  WAITING_FOR_ACTION = "waiting",
  ALL = "all",
}

export const useUsersProgressSA = (
  params: PaginationRequestDef & {
    keyword?: string;
    assignedSuperAdminIds?: string[];
    uncompletedTaskIdWithinActiveGoals?: string;
    showUserProgressType?: EUserProgressType;
  }
) =>
  useQuery({
    refetchInterval: 60000,
    queryKey: [...progressQueryKey, "list", params] as const,
    queryFn: ({ queryKey }) =>
      superAdminApi
        .get<UserProgressWithPaginationDef>("/super_admin/users_progress", {
          params: queryKey[3],
        })
        .then(({ data }) => data),
  });

export const useUserProgressSA = (params: { id?: string | null }) =>
  useQuery({
    refetchInterval: 60000,
    enabled: Boolean(params.id),
    queryKey: [...progressQueryKey, "details", params] as const,
    queryFn: () =>
      superAdminApi
        .get<UserProgress>(`/super_admin/users_progress/${params.id}`)
        .then(({ data }) => data),
  });

export const editUserProgressSA = ({
  userProgressId,
  ...params
}: {
  userProgressId: string;
  isWaitingForAction?: boolean;
  assignedSuperAdminId?: string | null;
}) => {
  return superAdminApi.put(`/super_admin/users_progress/${userProgressId}`, params);
};
