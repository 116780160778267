import { UserOutlined } from "@ant-design/icons";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { useCompaniesSA } from "@app/features/super-admin/api/super-admin.company.api";
import { SUPER_ADMIN_PAGE_SIZE } from "@app/features/super-admin/constants/super-admin.constants";
import {
  CompaniesSAColumnsDataDef,
  CompanyAdminDef,
  CompanySADef,
  LoginAsAdminDef,
  LoginUnclaimCompanyDef,
} from "@app/features/super-admin/types/super-admin.company.types";
import { store } from "@app/store/store";
import { ApprovalStatusEnum } from "@app/types/company-info.types";
import {
  Avatar,
  Badge,
  Col,
  Input,
  List,
  Modal,
  Row,
  Skeleton,
  Space,
  Spin,
  Switch,
  Table,
  TableColumnsType,
} from "antd";
import { TablePaginationConfig } from "antd/es/table/interface";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCompanyModal } from "./components/CompanyModal";

const { Search } = Input;

export const Companies = () => {
  const { t } = useTranslation();
  const { dispatch } = store;
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [loginAsCompanyLoading, setLoginAsCompanyLoading] = useState(false);
  // table params
  const [keyword, setKeyword] = useState("");
  const [onlyShowPaying, setOnlyShowPaying] = useState(false);
  const [onlyShowClaimed, setOnlyShowClaimed] = useState(true);
  // modals
  const [companyAdminsModalOpen, setCompanyAdminsModalOpen] = useState(false);
  const { setCompanyId } = useCompanyModal();
  // data
  const [selectedCompanyAdmins, setSelectCompanyAdmins] = useState<CompanyAdminDef[]>([]);
  const [selectedCompany, setSelectCompany] = useState<CompanySADef>();

  const companiesQuery = useCompaniesSA({
    keyword,
    isPaying: onlyShowPaying || undefined,
    isClaimed: onlyShowClaimed || undefined,
    limit: SUPER_ADMIN_PAGE_SIZE,
    offset: page,
  });

  // Admins modal
  const handleAdminsModalCancel = () => {
    setCompanyAdminsModalOpen(false);
  };

  const loginAsCompany = async (payload: LoginAsAdminDef | LoginUnclaimCompanyDef) => {
    setLoginAsCompanyLoading(true);
    try {
      await dispatch.superAdmin.loginCompanySA(payload);
      navigate(RoutePaths.DASHBOARD);
    } catch (err) {
      console.error(err);
    }
  };

  const handlePageChange = async (pagination: TablePaginationConfig) => {
    if (typeof pagination.current === "number" && typeof pagination.pageSize === "number") {
      setPage(pagination.current - 1);
    }
  };

  const getApprovalStatusBadge = (status: ApprovalStatusEnum) => {
    switch (status) {
      case ApprovalStatusEnum.APPROVED:
        return "success";
      case ApprovalStatusEnum.NOT_STARTED:
        return "default";
      case ApprovalStatusEnum.PENDING:
        return "processing";
      case ApprovalStatusEnum.REJECTED:
        return "error";
    }
  };

  const generatedData = (data: CompanySADef[]): CompaniesSAColumnsDataDef[] => {
    return data.map((item) => ({
      key: item.id,
      name: <a onClick={() => setCompanyId(item.id)}>{item.name}</a>,
      contactEmail: item.contactEmail,
      contactPhone: item.contactPhone,
      approvalStatus: (
        <Badge
          key="approvalStatus"
          status={getApprovalStatusBadge(item.approvalStatus)}
          text={item.approvalStatus}
        />
      ),
      hasPaidForJobAds: (
        <Badge
          key="hasPaidForJobAds"
          status={item.hasPaidForJobAds ? "success" : "default"}
          text={t(item.hasPaidForJobAds ? "paid" : "not paid")}
        />
      ),
      internalComment: item.internalComment,
      operation: (
        <Space size="middle">
          <a
            onClick={() => {
              setCompanyId(item.id);
            }}
          >
            {t("Settings")}
          </a>
          {item.admins.length ? (
            item.admins.length === 1 ? (
              <a
                onClick={() => {
                  loginAsCompany(item.admins[0]);
                }}
              >
                {t("Log in")}
              </a>
            ) : (
              <a
                onClick={() => {
                  setCompanyAdminsModalOpen(true);
                  setSelectCompany(item);
                  setSelectCompanyAdmins(item.admins as CompanyAdminDef[]);
                }}
              >
                {t("Admins")}
              </a>
            )
          ) : (
            <a
              onClick={() => {
                loginAsCompany({ targetCompanyId: item.id, controlCompany: true });
              }}
            >
              {t("Log in")}
            </a>
          )}
        </Space>
      ),
    }));
  };

  const columns: TableColumnsType<CompaniesSAColumnsDataDef> = [
    { title: t("Company name"), dataIndex: "name", key: "title" },
    { title: t("Email"), dataIndex: "contactEmail", key: "contactEmail", width: 200 },
    { title: t("Mobile phone"), dataIndex: "contactPhone", key: "contactPhone", width: 150 },
    { title: t("Approval status"), dataIndex: "approvalStatus", key: "approvalStatus", width: 180 },
    {
      title: t("Has paid for job ads"),
      dataIndex: "hasPaidForJobAds",
      key: "hasPaidForJobAds",
      width: 150,
    },
    {
      title: t("Comment"),
      dataIndex: "internalComment",
      key: "internalComment",
      ellipsis: true,
      width: 150,
    },
    { title: t("Actions"), dataIndex: "operation", key: "operation", fixed: "right", width: 200 },
  ];

  if (loginAsCompanyLoading) {
    return (
      <Space direction="vertical" size="large" style={{ width: "100%", textAlign: "center" }}>
        <div>Logging in as Company</div>
        <Spin />
      </Space>
    );
  }

  return (
    <>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Search
          placeholder={t("Search for company id, company name or contact email")}
          enterButton={t("Search")}
          size="large"
          onSearch={(value) => {
            setPage(0);
            setKeyword(value);
          }}
          allowClear
        />
        <Row>
          <Space size="large">
            <Col>
              <label htmlFor="onlyPaying" style={{ cursor: "pointer", marginRight: 8 }}>
                {t("Has paid for job ads")}
              </label>
              <Switch
                id="onlyPaying"
                checked={onlyShowPaying}
                onChange={() => {
                  setPage(0);
                  setOnlyShowPaying(!onlyShowPaying);
                }}
              />
            </Col>
            <Col>
              <label htmlFor="onlyClaimed" style={{ cursor: "pointer", marginRight: 8 }}>
                {t("Exclude scraped companies")}
              </label>
              <Switch
                id="onlyClaimed"
                checked={onlyShowClaimed}
                onChange={() => {
                  setPage(0);
                  setOnlyShowClaimed(!onlyShowClaimed);
                }}
              />
            </Col>
          </Space>
        </Row>
        <Table
          style={{ width: "100%" }}
          columns={columns}
          dataSource={generatedData(companiesQuery.data?.data ?? [])}
          loading={companiesQuery.isLoading}
          pagination={{
            current: page + 1,
            total: companiesQuery.data?.count,
            showTotal: (total) => `${t("Total")} ${total} ${t("Companies")}`,
            pageSize: SUPER_ADMIN_PAGE_SIZE,
            showSizeChanger: false,
            position: ["bottomLeft"],
          }}
          onChange={handlePageChange}
          scroll={{ x: 1250 }}
        />
      </Space>
      <Modal
        title={`${selectedCompany?.name} admins`}
        open={companyAdminsModalOpen}
        onCancel={handleAdminsModalCancel}
        footer={null}
      >
        <List
          itemLayout="horizontal"
          dataSource={selectedCompanyAdmins}
          loading={loginAsCompanyLoading}
          renderItem={(item: CompanyAdminDef) => (
            <List.Item
              actions={[
                <a
                  onClick={() => {
                    loginAsCompany(item);
                  }}
                  key="list-login"
                >
                  {t("Log in")}
                </a>,
              ]}
            >
              <Skeleton avatar title={false} loading={false} active>
                <List.Item.Meta
                  avatar={
                    item.picture ? (
                      <Avatar src={item.picture} />
                    ) : (
                      <Avatar size="small" icon={<UserOutlined />} />
                    )
                  }
                  title={`${item.firstName} ${item.lastName}`}
                />
              </Skeleton>
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};
