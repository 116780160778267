import {
  CarOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  MessageOutlined,
  SecurityScanOutlined,
  SettingOutlined,
  ShopOutlined,
  SolutionOutlined,
  TrophyOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { BauGPT } from "@app/components/pages/BauGPT/BauGPT";
import { Candidates } from "@app/components/pages/Candidates/Candidates";
import { Chat } from "@app/components/pages/Chat/Chat";
import { CompanyInfo } from "@app/components/pages/CompanyInfo";
import { CompanyTrades } from "@app/components/pages/CompanyTrades/CompanyTrades";
import { ConstructionProjects } from "@app/components/pages/ConstructionProjects";
import { Dashboard } from "@app/components/pages/Dashboard/Dashboard";
import { EmployeeBenefits } from "@app/components/pages/EmployeeBenefits/EmployeeBenefits";
import { EquipmentAndMachinery } from "@app/components/pages/EquipmentAndMachinery";
import { JobAdDetails } from "@app/components/pages/JobAdDetails/JobAdDetails";
import { JobAds } from "@app/components/pages/JobAds/JobAds";
import { KeyData } from "@app/components/pages/KeyData";
import { Settings } from "@app/components/pages/Settings/Settings";
import { SubmitForReview } from "@app/components/pages/SubmitForReview";
import { RouteDef, RoutePaths } from "@app/features/routes/types/routes.types";
import { SuperAdminStuff } from "@app/features/super-admin/components/SuperAdminStuff/SuperAdminStuff";
import { flattenRoutes } from "../helpers/routes.helper";

export const ALL_ROUTES: RouteDef[] = [
  {
    key: RoutePaths.DASHBOARD,
    label: "Dashboard",
    icon: <HomeOutlined />,
    component: Dashboard,
  },
  {
    key: RoutePaths.COMPANY_INFO_SUBMENU,
    label: "Company Info",
    icon: <ShopOutlined />,
    children: [
      {
        key: RoutePaths.COMPANY_INFO,
        parentKey: RoutePaths.COMPANY_INFO_SUBMENU,
        component: CompanyInfo,
        label: "Company Info",
        icon: <ShopOutlined />,
        description: "Edit basic information, such as your company name and logo",
      },
      {
        key: RoutePaths.COMPANY_TRADES,
        parentKey: RoutePaths.COMPANY_INFO_SUBMENU,
        component: CompanyTrades,
        label: "Company Trades",
        icon: <TrophyOutlined />,
        description: "What Trades do you have in your company?",
      },
      {
        key: RoutePaths.KEY_DATA,
        parentKey: RoutePaths.COMPANY_INFO_SUBMENU,
        component: KeyData,
        label: "Key Data",
        icon: <DashboardOutlined />,
        description: "Show us what your company is all about",
      },
      {
        key: RoutePaths.CONSTRUCTION_PROJECTS,
        parentKey: RoutePaths.COMPANY_INFO_SUBMENU,
        component: ConstructionProjects,
        label: "Construction Projects",
        icon: <EnvironmentOutlined />,
        description: "Show off the projects your company is most proud of",
      },
      {
        key: RoutePaths.EQUIPMENT_AND_MACHINERY,
        parentKey: RoutePaths.COMPANY_INFO_SUBMENU,
        component: EquipmentAndMachinery,
        label: "Equipment and Machinery",
        icon: <CarOutlined />,
        description: "Let's see what kind of equipment you're working with",
      },
      {
        key: RoutePaths.EMPLOYEE_BENEFITS,
        parentKey: RoutePaths.COMPANY_INFO_SUBMENU,
        component: EmployeeBenefits,
        label: "Employee Benefits",
        icon: <DollarCircleOutlined />,
        description: "Why should someone work for you?",
      },
    ],
  },
  {
    key: RoutePaths.JOB_ADS,
    component: JobAds,
    label: "Job ads",
    icon: <SolutionOutlined />,
    description: "Job ads",
  },
  {
    key: RoutePaths.JOB_AD_DETAILS,
    component: JobAdDetails,
    label: "Job ad details",
    icon: <SolutionOutlined />,
    description: "Job ads",
    hideInMenu: true,
    parentKey: RoutePaths.JOB_ADS,
  },
  {
    key: RoutePaths.CANDIDATES,
    component: Candidates,
    label: "Candidates",
    icon: <UserOutlined />,
    description: "Candidates",
    fullHeight: true,
  },
  {
    key: RoutePaths.CHAT,
    component: Chat,
    label: "Chats",
    icon: <MessageOutlined />,
  },
  {
    key: RoutePaths.BAUGPT,
    component: BauGPT,
    label: "BauGPT",
    icon: <MessageOutlined />,
  },
  {
    key: RoutePaths.SUBMIT_FOR_REVIEW,
    component: SubmitForReview,
    label: "Submit for review",
    description:
      "Submit your information so that we can approve you and publish your company to craftsmen in our app",
  },
  {
    key: RoutePaths.SETTINGS,
    component: Settings,
    label: "Settings",
    icon: <SettingOutlined />,
  },
  {
    key: RoutePaths.SUPER_ADMIN_STUFF,
    component: SuperAdminStuff,
    label: "Super admin stuff",
    icon: <SecurityScanOutlined />,
    hasFeatureFlag: true,
    fullHeight: true,
  },
];

export const ALL_ROUTES_FLATTEN = flattenRoutes(ALL_ROUTES);
