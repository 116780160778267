import { queryKeysSA } from "@app/features/super-admin/api/queryKeys";
import { IntegrationJobDef } from "@app/types/integration-jobs.types";
import { useQuery } from "@tanstack/react-query";
import { api } from "./api";

const getIntegrationJobs = (params: { searchQuery?: string }) => {
  return api.get<IntegrationJobDef[]>("/crafthunts/company_integration_jobs", {
    params,
  });
};

export const useIntegrationJobs = (params: { searchQuery?: string }) =>
  useQuery({
    queryKey: [...queryKeysSA.lists(), "integration-jobs", params] as const,
    queryFn: ({ queryKey }) => getIntegrationJobs(queryKey[3]).then(({ data }) => data),
  });
