import { JobQuestionApiResponse } from "@app/types/job-questions.types";
import { useQuery } from "@tanstack/react-query";
import {
  GetJobAdsSAParamsDef,
  JobAdSADef,
  JobAdsSAWithPaginationDef,
} from "../types/super-admin.job.types";
import { queryKeysSA } from "./queryKeys";
import { superAdminApi } from "./super-admin.api";

export const getJobAdsSA = (params: GetJobAdsSAParamsDef) => {
  return superAdminApi.get<JobAdsSAWithPaginationDef>("/super_admin/jobs", {
    params,
  });
};

export const useJobsSA = (params: GetJobAdsSAParamsDef) =>
  useQuery({
    queryKey: [...queryKeysSA.lists(), "jobs", params] as const,
    queryFn: ({ queryKey }) => getJobAdsSA(queryKey[3]).then(({ data }) => data),
  });

export const useJobSA = ({ jobId }: { jobId?: string | null }) =>
  useQuery({
    enabled: Boolean(jobId),
    queryKey: [...queryKeysSA.all, "jobs", jobId] as const,
    queryFn: () =>
      superAdminApi.get<JobAdSADef>(`/super_admin/jobs/${jobId}`).then(({ data }) => data),
  });

export const useJobsQuestionsSA = (params: { jobAdId?: string | null }) =>
  useQuery({
    enabled: Boolean(params.jobAdId),
    queryKey: [...queryKeysSA.lists(), "jobs-questions", params] as const,
    queryFn: ({ queryKey }) =>
      superAdminApi
        .get<JobQuestionApiResponse>("/crafthunts/job_questions", {
          params: queryKey[3],
        })
        .then(({ data }) => data),
  });
